import {
  Button,
  EditableField,
  FetchBoundary,
  StringInput,
  Segment,
  Message,
  Header,
  Action,
  Divider,
} from '@alphaflow/components'
import { useRouteMatch } from 'react-router-dom'
import { ArrowCircleRightIcon, TrashOutlineIcon } from '@alphaflow/icon'
import {
  OneClassroomResource,
  updateClassroomMutation,
} from 'resources/Classroom'
import {
  AllClassroomAssignmentsResource,
  createClassroomAssignmentMutation,
  deleteClassroomAssignmentMutation,
} from 'resources/ClassroomAssignment'
import CurrentUserResource from 'resources/Auth/CurrentUser'
import { useHistory } from 'react-router-dom'
import routes from 'config/routes'

const ClassroomPage = () => {
  const classroomId = useRouteMatch()?.params?.classroomId
  const [classroom, classroomFetchError] = OneClassroomResource.use(classroomId)
  const [assignments, assignmentsFetchError] =
    AllClassroomAssignmentsResource.use(classroomId)
  const [currentUser] = CurrentUserResource.use()
  const history = useHistory()
  return (
    <FetchBoundary
      fetchError={classroomFetchError || assignmentsFetchError}
      isFetching={!classroom || !assignments}
    >
      {() => (
        <>
          <br />
          <br />
          <br />
          <Header>
            Classroom:
            <EditableField
              InputComponent={StringInput}
              displayValue={classroom.name}
              value={classroom.name}
              onSaveChanges={name =>
                updateClassroomMutation({ classroomId, changes: { name } })
              }
            />
          </Header>
          <Divider className="_divider" />
          {assignments.length === 0 ? (
            <Message>
              <em>No assignments yet.</em>
            </Message>
          ) : (
            assignments.map(({ id, name }) => (
              <Segment
                key={id}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {name}&emsp;&emsp;
                <Action
                  standalone
                  primary
                  onClick={() => {
                    history.push(
                      routes.classroomAssignment.makePath({
                        classroomId,
                        assignmentId: id,
                      }),
                    )
                  }}
                >
                  <ArrowCircleRightIcon />
                </Action>
                &ensp;
                <Action
                  standalone
                  onClick={async () => {
                    await deleteClassroomAssignmentMutation({
                      classroomId,
                      assignmentId: id,
                    })
                  }}
                >
                  <TrashOutlineIcon />
                </Action>
              </Segment>
            ))
          )}
          <Divider className="_divider" />
          <Button
            standalone
            primary
            onClick={async () => {
              const assignmentId = await createClassroomAssignmentMutation({
                createdByUserId: currentUser.id,
                classroomId,
              })
              history.push(
                routes.classroomAssignment.makePath({
                  assignmentId,
                  classroomId,
                }),
              )
            }}
          >
            Create a new assignment
          </Button>
        </>
      )}
    </FetchBoundary>
  )
}

export default ClassroomPage
