import {
  classroomAssignmentTypeOptions,
  OneClassroomAssignmentResource,
} from 'resources/ClassroomAssignment'
import { AllClassroomAssignmentSubmissionsResource } from 'resources/ClassroomAssignmentSubmission'
import { Button, FetchBoundary, Header } from '@alphaflow/components'
import { useRouteMatch } from 'react-router-dom'
import './ViewClassroomAssignmentSubmissionsPage.scss'
import routes from 'config/routes'
import { OneClassroomResource } from 'resources/Classroom'
import { uniq } from 'lodash'
import { pluralize } from '@alphaflow/util'
import { BrowserUserResource } from 'resources/BrowserUser'

const ViewClassroomAssignmentSubmissionsPage = () => {
  const [user] = BrowserUserResource.use()
  const { classroomId, assignmentId } = useRouteMatch()?.params
  const [classroom, classroomFetchError] = OneClassroomResource.use(classroomId)
  const [assignment, assignmentFetchError] = OneClassroomAssignmentResource.use(
    { classroomId, assignmentId },
  )
  const [assignmentSubmissions, fetchError] =
    AllClassroomAssignmentSubmissionsResource.use({ classroomId, assignmentId })

  const participantsCount = uniq(
    assignmentSubmissions?.map(
      ({ createdByBrowserUserId }) => createdByBrowserUserId,
    ) || [],
  ).length

  const browserUserDidSubmit = Boolean(
    assignmentSubmissions?.find(
      ({ createdByBrowserUserId }) => createdByBrowserUserId === user.id,
    ),
  )

  const { ViewComponent = () => null } =
    classroomAssignmentTypeOptions[assignment?.type] || {}

  return (
    <FetchBoundary
      fetchError={fetchError || assignmentFetchError || classroomFetchError}
      isFetching={!assignmentSubmissions || !assignment || !classroom}
    >
      {() => (
        <div className="ViewClassroomAssignmentSubmissionsPage">
          <div className="__title">
            <Header className="__header">
              {classroom.name}: {assignment.name}
            </Header>
            <div>
              {pluralize({
                count: participantsCount,
                singular: 'participant has',
                plural: 'participants have',
              })}{' '}
              submitted.
              {!browserUserDidSubmit && (
                <>
                  &emsp;&emsp;&emsp;&emsp;
                  <Button
                    primary
                    onClick={() => {
                      window.open(
                        window.location.origin +
                          routes.createClassroomAssignment.makePath({
                            assignmentId,
                            classroomId,
                          }),
                      )
                    }}
                  >
                    Submit your response
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="__content">
            <ViewComponent assignmentSubmissions={assignmentSubmissions} />
          </div>
        </div>
      )}
    </FetchBoundary>
  )
}

export default ViewClassroomAssignmentSubmissionsPage
