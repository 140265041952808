import { describeResource, describeMutation } from '@alphaflow/resource'
import { firestore } from 'config/firebase'
import { BrowserStorageResource, writeBrowserStorage } from './BrowserStorage'

export const BrowserUserResource = describeResource('BrowserUser', {
  get: async () => {
    let { browserUserId } = await BrowserStorageResource.get()
    if (!browserUserId) return null
    return {
      ...(await (
        await firestore.collection('browserUsers').doc(browserUserId).get()
      ).data()),
      id: browserUserId,
    }
  },
})
export const OneBrowserUserResource = describeResource('OneBrowserUser', {
  get: async browserUserId => {
    return {
      ...(await (
        await firestore.collection('browserUsers').doc(browserUserId).get()
      ).data()),
      id: browserUserId,
    }
  },
})

export const connectBrowserUserMutation = describeMutation(
  'connectBrowserUser',
  async () => {
    const { browserUserId } = await BrowserStorageResource.get()
    if (!browserUserId) {
      const browserUserId = (await firestore.collection('browserUsers').add({}))
        .id
      await writeBrowserStorage(last => ({
        ...last,
        browserUserId,
      }))
      await BrowserStorageResource.refresh()
      await BrowserUserResource.refresh()
    }
  },
)

export const updateBrowserUserMutation = describeMutation(
  'updateBrowserUser',
  async (changes = {}) => {
    const { browserUserId } = await BrowserStorageResource.get()

    await firestore
      .collection('browserUsers')
      .doc(browserUserId)
      .set(changes, { merge: true })

    await BrowserUserResource.refresh()
  },
)
