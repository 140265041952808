import { firestore } from 'config/firebase'

export const deserializeCurrentUser = async user => {
  const userDoc = await firestore.collection('users').doc(user.uid).get()
  const isAdmin = (await user.getIdTokenResult()).claims.admin

  const {
    avatarId,
    handle = '',
    displayName,
    ...rest
  } = userDoc.exists ? userDoc.data() : {}

  return {
    id: user.uid,
    displayName: displayName || user.displayName || undefined,
    avatarId,
    handle: handle.toLowerCase(),
    isAdmin,
    ...rest,
  }
}
