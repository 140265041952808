import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import {
  classroomAssignmentTypeOptions,
  OneClassroomAssignmentResource,
} from 'resources/ClassroomAssignment'
import {
  Action,
  EditableField,
  FetchBoundary,
  Header,
  Message,
  StringInput,
} from '@alphaflow/components'
import routes from 'config/routes'
import { BrowserStorageResource } from 'resources/BrowserStorage'
import {
  BrowserUserResource,
  updateBrowserUserMutation,
} from 'resources/BrowserUser'
import './CreateClassroomAssignmentSubmissionPage.scss'
import { OneClassroomResource } from 'resources/Classroom'

const CreateClassroomAssignmentSubmissionPage = () => {
  const history = useHistory()
  const { classroomId, assignmentId } = useRouteMatch()?.params
  const [classroom] = OneClassroomResource.use(classroomId)
  const [assignment] = OneClassroomAssignmentResource.use({
    classroomId,
    assignmentId,
  })
  const [browserStorage] = BrowserStorageResource.use()
  const [user] = BrowserUserResource.use()

  const CreateComponent = React.useMemo(
    () =>
      classroomAssignmentTypeOptions[assignment?.type]?.CreateComponent || null,
    [assignment],
  )

  const [isFinished, setIsFinished] = React.useState(false)

  return (
    <FetchBoundary isFetching={!assignment || !browserStorage || !user}>
      {() => (
        <div className="CreateClassroomAssignmentSubmissionPage">
          <div className="__title">
            <Header>
              {classroom?.name}: {assignment?.name}
            </Header>
            {Boolean(assignment.promptText) && (
              <p className="_segment">{assignment.promptText}</p>
            )}
          </div>
          <div className="__title">
            <strong>Submission by:</strong>{' '}
            <EditableField
              InputComponent={StringInput}
              displayValue={user.name || 'Please enter a name...'}
              value={user.name}
              onSaveChanges={name =>
                updateBrowserUserMutation({
                  name,
                })
              }
            />
          </div>
          <div className="__content">
            {isFinished ? (
              <Message positive style={{ width: 300, margin: '30px auto' }}>
                <p>Thank you! Your submission was received.</p>
                <p>
                  <Action
                    onClick={() => {
                      history.push(
                        routes.viewClassroomAssignmentSubmissions.makePath({
                          classroomId,
                          assignmentId,
                        }),
                      )
                    }}
                  >
                    Click here to view it
                  </Action>
                </p>
              </Message>
            ) : (
              CreateComponent && (
                <CreateComponent
                  onFinish={() => {
                    setIsFinished(true)
                  }}
                  classroomId={classroomId}
                  assignmentId={assignmentId}
                />
              )
            )}
          </div>
        </div>
      )}
    </FetchBoundary>
  )
}

export default CreateClassroomAssignmentSubmissionPage
