import React from 'react'
import CurrentUserResource from 'resources/Auth/CurrentUser'
import LoginPage from 'components/LoginPage'
import { Route, Switch, useHistory } from 'react-router-dom'
import routes from 'config/routes'
import NotFoundPage from 'components/NotFoundPage'
import { Action, FetchBoundary } from '@alphaflow/components'
import './App.scss'
import { startLogOut } from 'config/firebase'
import { BrowserUserResource } from 'resources/BrowserUser'

const App = () => {
  const [currentUser, currentUserFetchError] = CurrentUserResource.use()
  React.useEffect(() => {
    if (currentUserFetchError) window.alert(currentUserFetchError)
  }, [currentUserFetchError])

  const [browserUser, browserUserResource] = BrowserUserResource.use()
  React.useEffect(() => {
    if (browserUserResource) window.alert(browserUserResource)
  }, [browserUserResource])

  const isLoadingAuth = currentUser === undefined
  const isNotLoggedIn = currentUser === null

  const renderedRoutes = React.useMemo(() => {
    const adminScopedRoutes = Object.values(routes).filter(({ isAdmin }) =>
      isAdmin ? currentUser?.isAdmin : true,
    )

    if (isNotLoggedIn)
      return [
        ...adminScopedRoutes
          .filter(({ isPrivate }) => !isPrivate)
          .map(({ isPrivate, ...rest }, index) => (
            <Route key={index} {...rest} />
          )),
        <Route key={adminScopedRoutes.length} component={LoginPage} />,
      ]

    return [
      ...adminScopedRoutes.map(({ isPrivate, ...rest }, index) => (
        <Route key={index} {...rest} />
      )),
      <Route key={adminScopedRoutes.length} component={NotFoundPage} />,
    ]
  }, [isNotLoggedIn, currentUser])

  const history = useHistory()

  return (
    <div className="App">
      <FetchBoundary isFetching={isLoadingAuth || !browserUser}>
        {() => (
          <>
            {!isNotLoggedIn && (
              <div className="__toolbar">
                <Action
                  onClick={() => {
                    history.push(routes.teacher.path)
                  }}
                >
                  All classrooms
                </Action>
                <Action onClick={startLogOut}>Log out</Action>
              </div>
            )}
            <div className="__content">
              <Switch>{renderedRoutes}</Switch>
            </div>
          </>
        )}
      </FetchBoundary>
    </div>
  )
}

export default App
