import { withLazy } from '@alphaflow/components'
import { describeMutation, describeResource } from '@alphaflow/resource'
import { firestore } from 'config/firebase'
import { isEqual } from 'lodash'
import { AllClassroomsResource } from './Classroom'

const CreateMosaicWorkspace = withLazy({
  lazyImport: () => import('components/CreateMosaicWorkspace'),
})
const ViewMosaic = withLazy({
  lazyImport: () => import('components/ViewMosaic'),
})
const CreateMapAssignmentSpecs = withLazy({
  lazyImport: () => import('components/CreateMapAssignmentSpecs'),
})
const CreateMapWorkspace = withLazy({
  lazyImport: () => import('components/CreateMapWorkspace'),
})
const ViewMap = withLazy({
  lazyImport: () => import('components/ViewMap'),
})

export const AllClassroomAssignmentsResource = describeResource(
  'AllClassroomAssignments',
  {
    get: async classroomId => {
      const classroomAssignments = []
      ;(
        await firestore
          .collection('classrooms')
          .doc(classroomId)
          .collection('classroomAssignments')
          .get()
      ).forEach(document => {
        classroomAssignments.push({
          id: document.id,
          ...document.data(),
        })
      })
      return classroomAssignments
    },
  },
)

export const OneClassroomAssignmentResource = describeResource(
  'OneClassroomAssignment',
  {
    get: async ({ classroomId, assignmentId }) => {
      return {
        ...(await (
          await firestore
            .collection('classrooms')
            .doc(classroomId)
            .collection('classroomAssignments')
            .doc(assignmentId)
            .get()
        ).data()),
        id: classroomId,
      }
    },
    areIdentitiesEqual: isEqual,
  },
)

export const createClassroomAssignmentMutation = describeMutation(
  'createClassroomAssignment',
  async ({ createdByUserId, classroomId, name = 'My New Assignment' }) => {
    const newClassroomRef = await firestore
      .collection('classrooms')
      .doc(classroomId)
      .collection('classroomAssignments')
      .add({
        createdByUserId,
        name,
      })
    await OneClassroomAssignmentResource.refresh()
    await AllClassroomsResource.refresh()
    await AllClassroomAssignmentsResource.refresh()

    return newClassroomRef.id
  },
)

export const updateClassroomAssignmentMutation = describeMutation(
  'updateClassroomAssignment',
  async ({ classroomId, assignmentId, changes = {} }) => {
    await firestore
      .collection('classrooms')
      .doc(classroomId)
      .collection('classroomAssignments')
      .doc(assignmentId)
      .set(changes, { merge: true })

    await OneClassroomAssignmentResource.refresh()
    await AllClassroomsResource.refresh()
    await AllClassroomAssignmentsResource.refresh()
  },
)

export const deleteClassroomAssignmentMutation = describeMutation(
  'deleteClassroomAssignment',
  async ({ assignmentId, classroomId }) => {
    if (!window.confirm('Are you sure? This cannot be undone.')) return

    await firestore
      .collection('classrooms')
      .doc(classroomId)
      .collection('classroomAssignments')
      .doc(assignmentId)
      .delete()

    await OneClassroomAssignmentResource.refresh()
    await AllClassroomsResource.refresh()
    await AllClassroomAssignmentsResource.refresh()
  },
)

export const classroomAssignmentTypeOptions = {
  mosaic: {
    key: 'mosaic',
    label: 'Mosaic',
    CreateAssignmentSpecsComponent: () => null,
    CreateComponent: CreateMosaicWorkspace,
    ViewComponent: ViewMosaic,
  },
  map: {
    key: 'map',
    label: 'Map',
    CreateAssignmentSpecsComponent: CreateMapAssignmentSpecs,
    CreateComponent: CreateMapWorkspace,
    ViewComponent: ViewMap,
  },
}
