import { describeMutation, describeResource } from '@alphaflow/resource'
import { firestore } from 'config/firebase'

export const AllClassroomsResource = describeResource('AllClassrooms', {
  get: async createdByUserId => {
    const classrooms = []
    ;(
      await firestore
        .collection('classrooms')
        .where('createdByUserId', '==', createdByUserId)
        .get()
    ).forEach(document => {
      classrooms.push({
        id: document.id,
        ...document.data(),
      })
    })
    return classrooms
  },
})

export const OneClassroomResource = describeResource('OneClassroom', {
  get: async classroomId => {
    return {
      ...(await (
        await firestore.collection('classrooms').doc(classroomId).get()
      ).data()),
      id: classroomId,
    }
  },
})

export const createClassroomMutation = describeMutation(
  'createClassroom',
  async ({ createdByUserId, name = 'My New Classroom' }) => {
    const newClassroomRef = await firestore.collection('classrooms').add({
      createdByUserId,
      name,
    })
    await AllClassroomsResource.refresh(createdByUserId)
    return newClassroomRef.id
  },
)
export const updateClassroomMutation = describeMutation(
  'updateClassroom',
  async ({ classroomId, changes = {} }) => {
    await firestore
      .collection('classrooms')
      .doc(classroomId)
      .set(changes, { merge: true })

    await AllClassroomsResource.refresh()
    await OneClassroomResource.refresh(classroomId)
  },
)

export const deleteClassroomMutation = describeMutation(
  'deleteClassroom',
  async ({ createdByUserId, classroomId }) => {
    if (!window.confirm('Are you sure? This cannot be undone.')) return

    await firestore.collection('classrooms').doc(classroomId).delete()
    await AllClassroomsResource.refresh(createdByUserId)
  },
)
