import { Button, Header } from '@alphaflow/components'
import { startGoogleLogin } from 'config/firebase'

const LoginPage = () => (
  <>
    <Header>Rediscovering Place Curriculum</Header>
    <br />
    <Button primary onClick={startGoogleLogin}>
      Log in with Google
    </Button>
  </>
)

export default LoginPage
