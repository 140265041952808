import { describeResource, describeMutation } from '@alphaflow/resource'

const browserStorageKey = 'placecurriculum.siteless.co'

export const readBrowserStorage = async () => {
  const deserialized = JSON.parse(
    localStorage.getItem(browserStorageKey) || JSON.stringify({}),
  )

  return deserialized
}

export const writeBrowserStorage = async writeWith => {
  const last = await readBrowserStorage()
  const next = writeWith(last)
  localStorage.setItem(browserStorageKey, JSON.stringify(next))
  return next
}

export const BrowserStorageResource = describeResource('BrowserStorage', {
  get: readBrowserStorage,
})

export const setBrowserStorageMutation = describeMutation(
  'setBrowserStorage',
  async changesOrMakeChanges => {
    // necessary to make sure we have *something* for last calcs, and that
    // we don't always re-get when there's something in the resource store
    // and we want to maintain object identities

    let last
    await BrowserStorageResource.yield(
      undefined,
      (identity, resourceStoreLast) => {
        last = resourceStoreLast
        return last
      },
    )

    const changes =
      typeof changesOrMakeChanges === 'function'
        ? changesOrMakeChanges(last || (await BrowserStorageResource.get()))
        : changesOrMakeChanges

    await BrowserStorageResource.yield(undefined, (identity, last) => {
      return { ...last, ...changes }
    })

    try {
      await writeBrowserStorage(last => ({
        ...last,
        ...changes,
      }))
    } catch (error) {
      await BrowserStorageResource.refresh()
    }
  },
)
