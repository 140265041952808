import {
  Action,
  Button,
  FetchBoundary,
  Message,
  Segment,
} from '@alphaflow/components'
import {
  AllClassroomsResource,
  createClassroomMutation,
  deleteClassroomMutation,
} from 'resources/Classroom'
import { useHistory } from 'react-router-dom'
import routes from 'config/routes'
import CurrentUserResource from 'resources/Auth/CurrentUser'
import { ArrowCircleRightIcon, TrashOutlineIcon } from '@alphaflow/icon'

const TeacherPage = () => {
  const history = useHistory()
  const [currentUser] = CurrentUserResource.use()
  const [classrooms, classroomFetchError] = AllClassroomsResource.use(
    currentUser?.id || null,
  )

  return (
    <>
      <br />
      <br />
      <br />
      <Button
        standalone
        primary
        onClick={async () => {
          const classroomId = await createClassroomMutation({
            createdByUserId: currentUser.id,
          })
          history.push(routes.classroom.makePath({ classroomId }))
        }}
      >
        Create a classroom
      </Button>
      <br />
      <FetchBoundary isFetching={!classrooms} fetchError={classroomFetchError}>
        {() =>
          classrooms.length === 0 ? (
            <Message>
              <em>No classrooms yet.</em>
            </Message>
          ) : (
            classrooms.map(({ id, name }) => (
              <Segment
                key={id}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {name}&emsp;&emsp;
                <Action
                  standalone
                  primary
                  onClick={() => {
                    history.push(routes.classroom.makePath({ classroomId: id }))
                  }}
                >
                  <ArrowCircleRightIcon />
                </Action>
                &ensp;
                <Action
                  standalone
                  onClick={async () => {
                    await deleteClassroomMutation({
                      createdByUserId: currentUser.id,
                      classroomId: id,
                    })
                  }}
                >
                  <TrashOutlineIcon />
                </Action>
              </Segment>
            ))
          )
        }
      </FetchBoundary>
    </>
  )
}

export default TeacherPage
