import { describeMutation } from '@alphaflow/resource'
import CurrentUserResource from './CurrentUser'
import { deserializeCurrentUser } from './services'
import { auth, firestore } from 'config/firebase'

export const acceptAuthChangeMutation = describeMutation(
  'acceptAuthChange',
  async nextUser => {
    const deserializedNextUser = nextUser
      ? await deserializeCurrentUser(nextUser)
      : null
    await CurrentUserResource.yield(undefined, () => deserializedNextUser)
  },
)

export const logOutMutation = describeMutation('logOut', async () => {
  await CurrentUserResource.yield(undefined, () => null)
  await auth.signOut()
})

export const updateUserPropertiesMutation = describeMutation(
  'updateUserProperties',
  async ({
    userId,
    handle,
    avatarId,
    prefersLeftHandMode,
    prefersLanguageKey,
  }) => {
    const updates = {}
    if (handle !== undefined) updates.handle = handle.toLowerCase()
    if (avatarId !== undefined) updates.avatarId = avatarId
    if (prefersLeftHandMode !== undefined)
      updates.prefersLeftHandMode = prefersLeftHandMode
    if (prefersLanguageKey !== undefined)
      updates.prefersLanguageKey = prefersLanguageKey

    let revertTo
    await CurrentUserResource.yield(undefined, (identity, last) => {
      revertTo = last
      if (last === null) return last
      return {
        ...last,
        ...updates,
      }
    })

    try {
      await firestore
        .collection('users')
        .doc(userId)
        .set(updates, { merge: true })
    } catch (error) {
      window.alert(error)
      await CurrentUserResource.yield(undefined, (identity, last) => revertTo)
    }
  },
)
