import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

try {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
  })
} catch (error) {
  window.alert(error)
}

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

export const startGoogleLogin = async () => {
  try {
    await firebase.auth().signInWithRedirect(googleAuthProvider)
  } catch (error) {
    window.alert(error)
  }
}

export const startLogOut = () => firebase.auth().signOut()

export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const auth = firebase.auth()
export const dataTypes = {
  timestamp: firebase.firestore.Timestamp,
}

export default firebase
