import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import {
  classroomAssignmentTypeOptions,
  OneClassroomAssignmentResource,
  updateClassroomAssignmentMutation,
} from 'resources/ClassroomAssignment'
import {
  Action,
  CopyText,
  EditableField,
  FetchBoundary,
  Divider,
  Header,
  Segment,
  SelectInput,
  StringInput,
  TextInput,
} from '@alphaflow/components'
import routes from 'config/routes'
import {
  AllClassroomAssignmentSubmissionsResource,
  deleteClassroomAssignmentSubmissionMutation,
} from 'resources/ClassroomAssignmentSubmission'
import { OneClassroomResource } from 'resources/Classroom'
import BrowserUserName from './BrowserUserName'

const ClassroomAssignmentPage = () => {
  const history = useHistory()
  const { classroomId, assignmentId } = useRouteMatch()?.params
  const [classroom, classroomFetchError] = OneClassroomResource.use(classroomId)
  const [assignment, assignmentFetchError] = OneClassroomAssignmentResource.use(
    { classroomId, assignmentId },
  )
  const [assignmentSubmissions, fetchError] =
    AllClassroomAssignmentSubmissionsResource.use({ classroomId, assignmentId })

  const CreateSpecsComponent =
    classroomAssignmentTypeOptions[assignment?.type]
      ?.CreateAssignmentSpecsComponent

  return (
    <FetchBoundary
      fetchError={assignmentFetchError || classroomFetchError || fetchError}
      isFetching={!assignment || !classroom || !assignmentSubmissions}
    >
      {() => (
        <>
          <br />
          <br />
          <br />
          <Action
            onClick={() => {
              history.push(routes.classroom.makePath({ classroomId }))
            }}
          >
            Classroom: {classroom.name}
          </Action>
          <Header>
            Assignment:{' '}
            <EditableField
              InputComponent={StringInput}
              displayValue={assignment.name}
              value={assignment.name}
              onSaveChanges={name =>
                updateClassroomAssignmentMutation({
                  classroomId,
                  assignmentId,
                  changes: { name },
                })
              }
            />
          </Header>
          <Divider className="_divider" />
          <Segment className="_segment">
            <Header>Assignment Details</Header>
            <p>
              <strong>Assignment type:</strong>
              <br />
              <EditableField
                InputComponent={SelectInput}
                inputComponentProps={{
                  options: Object.values(classroomAssignmentTypeOptions).map(
                    ({ key, label }) => ({ key, value: key, text: label }),
                  ),
                }}
                displayValue={
                  classroomAssignmentTypeOptions[assignment.type]?.label ||
                  'Please select'
                }
                value={assignment.type}
                onSaveChanges={type =>
                  updateClassroomAssignmentMutation({
                    classroomId,
                    assignmentId,
                    changes: { type },
                  })
                }
              />
            </p>
            {CreateSpecsComponent && (
              <CreateSpecsComponent
                classroomId={classroomId}
                assignmentId={assignmentId}
              />
            )}
            <p>
              <strong>Prompt for participants (optional):</strong>
              <br />
              <EditableField
                InputComponent={TextInput}
                displayValue={
                  assignment.promptText || 'Please enter prompt text'
                }
                value={assignment.promptText}
                onSaveChanges={promptText =>
                  updateClassroomAssignmentMutation({
                    classroomId,
                    assignmentId,
                    changes: { promptText },
                  })
                }
              />
            </p>
          </Segment>
          <Segment className="_segment">
            <Header>Reference</Header>
            <p style={{ wordBreak: 'break-all' }}>
              <strong>Notion embed link:</strong>
              <br />
              <CopyText>
                {window.location.origin +
                  routes.viewClassroomAssignmentSubmissions.makePath({
                    classroomId,
                    assignmentId,
                  })}
              </CopyText>
            </p>
            <p style={{ wordBreak: 'break-all' }}>
              <strong>Direct submission link:</strong>
              <br />
              <CopyText>
                {window.location.origin +
                  routes.createClassroomAssignment.makePath({
                    classroomId,
                    assignmentId,
                  })}
              </CopyText>
            </p>
            <p>
              <strong>Submissions:</strong>
              <br />
              {assignmentSubmissions.length
                ? assignmentSubmissions.map(
                    ({ id, createdByBrowserUserId, createdAt }) => (
                      <React.Fragment key={id}>
                        <BrowserUserName id={createdByBrowserUserId} />•{' '}
                        {createdAt.toDate().toDateString()}
                        &emsp;&emsp;
                        <Action
                          onClick={async () => {
                            if (
                              !window.confirm(
                                'Are you sure you want to remove this submission? This action cannot be undone.',
                              )
                            )
                              return

                            await deleteClassroomAssignmentSubmissionMutation({
                              classroomId,
                              assignmentId,
                              submissionId: id,
                            })
                          }}
                        >
                          Remove
                        </Action>
                        <br />
                      </React.Fragment>
                    ),
                  )
                : 'No submissions yet.'}
            </p>
          </Segment>
          <br />
          <br />
        </>
      )}
    </FetchBoundary>
  )
}

export default ClassroomAssignmentPage
