import TeacherPage from 'components/TeacherPage'
import ClassroomAssignmentPage from 'components/ClassroomAssignmentPage'
import CreateClassroomAssignmentSubmissionPage from 'components/CreateClassroomAssignmentSubmissionPage'
import ClassroomPage from 'components/ClassroomPage'
import ViewClassroomAssignmentSubmissionsPage from 'components/ViewClassroomAssignmentSubmissionsPage'

const routes = {
  teacher: {
    key: 'teacher',
    isPrivate: true,
    path: '/teacher',
    exact: true,
    component: TeacherPage,
  },
  classroom: {
    key: 'classroom',
    isPrivate: true,
    path: '/classroom/:classroomId',
    makePath: ({ classroomId }) => `/classroom/${classroomId}`,
    exact: true,
    component: ClassroomPage,
  },
  classroomAssignment: {
    key: 'classroomAssignment',
    isPrivate: true,
    path: '/classroom/:classroomId/assignment/:assignmentId',
    makePath: ({ classroomId, assignmentId }) =>
      `/classroom/${classroomId}/assignment/${assignmentId}`,
    exact: true,
    component: ClassroomAssignmentPage,
  },
  createClassroomAssignment: {
    key: 'createClassroomAssignment',
    path: '/classroom/:classroomId/assignment/:assignmentId/create',
    makePath: ({ classroomId, assignmentId }) =>
      `/classroom/${classroomId}/assignment/${assignmentId}/create`,
    exact: true,
    component: CreateClassroomAssignmentSubmissionPage,
  },
  viewClassroomAssignmentSubmissions: {
    key: 'viewClassroomAssignmentSubmissions',
    path: '/classroom/:classroomId/assignment/:assignmentId/viewSubmissions',
    makePath: ({ classroomId, assignmentId }) =>
      `/classroom/${classroomId}/assignment/${assignmentId}/viewSubmissions`,
    exact: true,
    component: ViewClassroomAssignmentSubmissionsPage,
  },
}
// {
//   path: '/privacy-policy',
//   exact: true,
//   component: PrivacyPolicyPage,
// },
// {
//   path: '/terms-of-use',
//   exact: true,
//   component: TermsOfUsePage,
// },
// {
//   isPrivate: true,
//   path: '/link/:linkId',
//   exact: true,
//   component: LinkPage,
// },
// {
//   isPrivate: true,
//   isAdmin: true,
//   path: '/admin',
//   exact: true,
//   component: AdminPage,
// },

export default routes
