import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import { BrowserRouter } from 'react-router-dom'
import { auth } from 'config/firebase'
import { Message, ErrorBoundary } from '@alphaflow/components'
import { acceptAuthChangeMutation } from 'resources/Auth/mutations'
import '@alphaflow/style'
import '@alphaflow/components/build/styles.css'
import 'style/index.scss'
import { connectBrowserUserMutation } from 'resources/BrowserUser'

auth.onAuthStateChanged(acceptAuthChangeMutation)
connectBrowserUserMutation()

const MOUNT_NODE = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={<Message negative>Something went wrong on this page.</Message>}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  MOUNT_NODE,
)
