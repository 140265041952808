import { describeMutation, describeResource } from '@alphaflow/resource'
import { firestore, dataTypes } from 'config/firebase'
import { isEqual } from 'lodash'

export const AllClassroomAssignmentSubmissionsResource = describeResource(
  'AllClassroomAssignmentSubmissions',
  {
    get: async ({ classroomId, assignmentId }) => {
      const classroomAssignmentSubmissions = []
      ;(
        await firestore
          .collection('classrooms')
          .doc(classroomId)
          .collection('classroomAssignments')
          .doc(assignmentId)
          .collection('classroomAssignmentSubmissions')
          .get()
      ).forEach(document => {
        classroomAssignmentSubmissions.push({
          id: document.id,
          ...document.data(),
        })
      })
      return classroomAssignmentSubmissions
    },
    areIdentitiesEqual: isEqual,
  },
)

export const createClassroomAssignmentSubmissionMutation = describeMutation(
  'createClassroomAssignmentSubmission',
  async ({ createdByBrowserUserId, classroomId, assignmentId, type, data }) => {
    const newClassroomRef = await firestore
      .collection('classrooms')
      .doc(classroomId)
      .collection('classroomAssignments')
      .doc(assignmentId)
      .collection('classroomAssignmentSubmissions')
      .add({
        createdAt: dataTypes.timestamp.fromDate(new Date()),
        createdByBrowserUserId,
        type,
        data,
      })
    await AllClassroomAssignmentSubmissionsResource.refresh({
      classroomId,
      assignmentId,
    })
    return newClassroomRef.id
  },
)

export const deleteClassroomAssignmentSubmissionMutation = describeMutation(
  'deleteClassroomAssignmentSubmission',
  async ({ classroomId, assignmentId, submissionId }) => {
    await firestore
      .collection('classrooms')
      .doc(classroomId)
      .collection('classroomAssignments')
      .doc(assignmentId)
      .collection('classroomAssignmentSubmissions')
      .doc(submissionId)
      .delete()
    await AllClassroomAssignmentSubmissionsResource.refresh({
      classroomId,
      assignmentId,
    })
  },
)
